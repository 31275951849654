// ++++++++++++++++ min 768px ++++++++++++++++++++++
@include media-breakpoint-up(md) {

  h1 {
      font-size: 2.75rem;
      padding-top: 0rem;
    }

  .slider-content {
    button {
      right: calc(50% - 195px);
    }
  }

  .p-5 {
    .suggestions__items {
      .suggestions__box {
        width: 31%;
      }
    }
  }

  .card-deck {
    .card {
      flex: auto;
    }
  }

  // body.home{
  //     span.badge-dark{
  //         font-size: 65%;
  //     }
  // }

  footer.copy-footer {
    // .container {
    //   @include flex-direction(row);
    //   @include align-items(baseline);
    // }
    p{
      margin-bottom: 0;
    }
    ul {
      margin-bottom: 0;      
      li {
        display: inline-block;
        margin-right: 0;

        margin-left: 10px;
      }
    }
  }

  // .grill-help {
  //   ul {
  //     @include justify-content(space-around);

  //     li {
  //       @include flex(1, 0, 33%);
  //     }
  //   }
  // }

  // .two-column-text {
  //   &-content {
  //     @include flexbox();
  //   }
  // }

  .rezepte {
    background: url("./../../images/bg-rezepte.jpg") no-repeat;
    background-size: cover;

    .rezept {
      border: none;
    }
  }

  article{
    .entry-content{
      h2{
        font-size: 1.5rem;
      }
    }
  }

  body.single {
    aside.sidebar {
      background-color: $color-body-bg;
  
      .table-header {
        h2 {
          font-size: 1.5rem;
        }
      }

      .sidebar-zutaten {
        // Tabelle Zutaten in Sidebar
        table{
          tbody{
            th{
              font-size: 0.875rem;
            }
          }
        }        
      }
    }    
  }

  body.single {
    .tipbox{
      display: flex;
      width: 100%;
      flex-direction: row;
      div{
        display: flex;
        height: auto;
        margin: 0;
        position: relative;
      }
      .tip_white{ 
        // background-color: #f4efe3;
        width: 22%;
        clip-path: none;
        height: inherit;
        &::after{
          content: " ";
          height: 100%;
          width: 30px;
          position: absolute;
          top: 0;
          right: -30px;
          background-color: #f4efe3;
          clip-path: polygon(0 0, 0 100%, 100% 50%);
          z-index: 1;
        }
      }
      .tipbox__tip{
        height: inherit;
        margin: inherit;
        clip-path: none;

        // background: #f9b91f;
        line-height: 100px;

        // min-width: 100px;
        width: 94%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: bold;
        background: #f9b91f url("/images/tipp-klecks-mit-schrift.svg") no-repeat center center;
        &::after{
          content: " ";
          height: 100%;
          width: 30px;
          position: absolute;
          top: 0;
          right: -30px;
          background-color: #f9b91f;
          clip-path: polygon(0 0, 0 100%, 100% 50%);        
          z-index: 2;          
        }
      }
      .tipbox__text{
        margin-top: 0;
        background: #d8cec2;
        padding: 20px 20px 20px 50px;
        width: 80%;
        flex-direction: column;
      }  
    }  

    .entry-meta {
      span.badge-dark {
        .zeit{
          position: relative;
          padding-left: 26px;
          &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: url("/images/zeit-icon.svg") no-repeat;
            width: 22px;
            height: 22px;
          }        
        }        
      }
    }
  }

}
