body.post-type-archive-recipes{
    background-color: $color-bright-bg;
    header.container-fluid{
        padding-left: 0;
        padding-right: 0;
    }
    #breadcrumbs{
        display: none;
    }
    .top-search{
        padding-left: 15px;
        padding-right: 15px;
    }
    .recipes-header{
        max-width: 600px;
        height: 140px;
        margin: 0 auto;
        background: url("https://www.grill-profis.eu/wp-content/themes/wsam-portal/dist/images/klecks-bg.svg") no-repeat top center;
        background-size: auto;
        background-position: center -95px;
    }
    .recipes-h1{
        color: inherit;
        text-align: center;
        padding-top: 20px;
    }

    .tagesrezept{
        // background: url("/images/rezept-des-tages-bg.png") no-repeat;
        // background-size: 100% 100%;
        margin-top: 30px;
        &-top{
            padding: 10px 0;
            background: url("/images/tagesrezept-top.png") no-repeat;
            background-size: cover;
            .left{

                .img-container{
                    width: 100%;
                    max-width: 300px;
                    height: 250px;
                    position: relative;
                    background: url("/images/brett.png") no-repeat; 
                    background-size: contain;
                }    
                img{
                    width: 254px;
                    height: 148px;
                    rotate: 45;
                    transform: rotate(-6.5deg);
                    position: absolute;
                    left: 24px;
                    top: 30px;
                    object-fit: cover;                    
                }     
            }
            .right{
                padding: 0 0 0 20px;
                h2{
                    font-family: "Londrina Solid", cursive;
                    text-align: left;
                    color: $color-white;
                    margin-bottom: 0;
                }
                time{
                    color: $color-gold;
                }
                h3{
                    font-family: Oswald, sans-serif;
                    color: $color-white;
                    text-align: left;
                    margin-top: 20px;
                }
                .intro{
                    color: $color-white;
                    font-size: 24px;
                }
            }
          }     
          
        &-bottom{
            background: url("/images/tagesrezept-bottom.png") no-repeat;
            background-size: cover;
            .slider-text{
                color: $color-white;
                font-size: 20px;

                // margin-top: -20px;
                // margin-top: 20px;
                text-align: center;
            }
            /* Carousel Slider */ 
            .MultiCarousel {
                float: left;
                overflow: hidden;
                padding: 15px;
                width: 100%;
                position: relative;

                // right: 20px;
                right: 0;
              }
              
              .MultiCarousel .MultiCarousel-inner {
                transition: 1s ease all;
                float: left;
              }
                            
              .MultiCarousel .MultiCarousel-inner .item {
                float: left;
                color: #666;
                display: flex;
                justify-content: center;
                position: relative;
                a{
                    width: 160px;
                    height: 110px;
                    display: block;            

                    // position: relative;
                    .middle {
                        transition: .4s ease;
                        opacity: 0;
                        position: absolute;

                        // top: 50%;
                        // left: 50%;
                        transform: translate(0);
                        transform: translate(0);
                        background-color: black;
                        width: 160px;
                        height: 110px;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        color: white;                    
                    }
                    
                    img{
                    width: 160px;
                    height: 110px;
                    object-fit: cover;
                    transition: .4s ease;
                    backface-visibility: hidden;
                    opacity: 1;
                    }
                    &:hover{
                        img {
                            opacity: 0.3;
                        }
                        .middle {
                            opacity: 0.7;
                        }                    
                    }
                }
                  
              }
            .carousel-control-prev, .carousel-control-next{
                width: 40px;
                height: 40px;
                border-radius: 100px;
                border: 1px solid $color-white;
                color: $color-white;
                background-color: rgb(0 0 0 / 40%);
                cursor: pointer;
                top: calc(50% - 20px);
                &:hover{
                    background-color: rgb(0 0 0 / 80%); 
                }                
            } 
            .carousel-control-prev{
                left: 15px;
            }
            .carousel-control-next{
                right: 15px;
            }            
        }
    }

    .all-recipes{
        background-color: $color-white;

        h3{
            text-align: left;
        }

        .container-xl.row{
            margin-left: 0;
            margin-right: 0;
        }
        .rezept{
            border: 1px solid $color-gold;
        }

        .rezept-top{
            position: relative;
            .rezept-image{
                min-height: 200px;
            }
            img{
                object-fit: cover;
                width: 100%;
                height: 200px;    
                box-shadow: none;            
            }
            .s-date{
                position: absolute;
                top: 10px;
                left: auto;
                width: 136px;
                height: 24px;
                color: #968273;
                background: url("/images/ribbon_weiss.png") no-repeat;
                font-family: Oswald, sans-serif;
                font-size: 0.75rem;
                text-transform: uppercase;
                padding: 2px 5px;
                z-index: 10;                
            }
        }

        h4{
            color: $color-black;
            text-transform: uppercase;
            font-family: Oswald, sans-serif;
            font-size: 16px;
            text-align: left;
            margin-top: 5px;
            font-weight: normal;
        }      
        
        .entry-meta{
            margin-top: auto;
        }

        .btn-primary{
            font-family: Oswald, sans-serif;
        }
    }

    .zubehoer{
        background-color: $color-white;

        h3{
            text-align: left;
        }
        img{
            box-shadow: none;
        }
        .zubehoer-liste{
            margin-top: 20px;
        }
        .zubehoer-header{
            background-color: $color-gold;
            color: $color-white;
            text-transform: uppercase;
            font-family: Oswald, sans-serif;
            .first{
                width: 150px;
            }
            .second{
                margin-left: 20px;
            }
        }        
        .zubehoer-item{
            border: 1px solid $color-gold;
            a{
                cursor: pointer;
                &:hover{
                    text-decoration: none;
                }
            }
            .image{
                width: 150px;
                height: 150px;
                position: relative;
                img{
                    object-fit: cover;
                }
                &::after{
                    content: "";
                    right: -30px;
                    top: 0;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 75px 0 75px 30px;
                    border-color: transparent transparent transparent rgb(150 130 115 / 50%);                    
                }
            }
            .text{
                margin-left: 50px;
                padding: 10px 0;
                color: $color-black;
            }
        }
    }

    .entry-meta{
        color: $color-gold;
        font-size: 12px;
        text-transform: uppercase;
        .zeit, .personen, .level{
            position: relative;
            padding-left: 26px;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
              }                         
        }
        .zeit{
            &::before{
              background: url("/images/zeit-icon.svg") no-repeat;
              width: 22px;
              height: 22px;    
            }
        }
        .personen{
            &::before{
              background: url("/images/person-icon.png") no-repeat;
              width: 22px;
              height: 22px;    
            }   
        }    
        .level{
            &::before{
              background: url("/images/level-icon.png") no-repeat;
              width: 22px;
              height: 22px;    
            }   
        }                                    
    }    

    aside.sidebar{
        background-color: $color-bright-bg;
    }
}
