// ++++++++++++++  min 1200px ++++++++++++++++++++++++++++
@include media-breakpoint-up(xl) {
  .p-5 {
    padding: 2vw $container_padding !important;

    .suggestions_{
      &_items {
      @include justify-content(space-between);
      font-size: 98px !important;
        .suggestions__box {
          margin: 0;
          width: 22%;

          // .suggestions__txt{
          //     font-size: 1.5vw;
          // }
        }
      }
    }
  }

  .card-deck {
    .card {
      @include flex(1, 0, 0%);
    }
  }

  body.single {

    div.tipp{
      height: 190px;
      background: url("/images/TIPP.svg") no-repeat;
      
      .tipp-content{
        
        margin-left: 215px;

      }
    }    
  }
}
