.gpcta {
  margin: 10px auto 15px auto;
  width: 85%;
  max-width: 85%;

  @media (min-width: 992px) {
    width: 70%;
    max-width: 70%;
  }

  border: 1px solid $color-black;
  background-color: $color-yellow;

  &:hover {
    background-color: $color-lightdark-yellow;
  }
  a {
    font-family: Oswald, sans-serif;
    font-size: 1.3rem;
    padding: 10px 0px 10px 0px;
    color: $color-black;
    display: block;
    text-align: center;
    &:hover {
      text-decoration: none;
    }
  }

  &.redcta {
    border-color: $color-light-grey;
    background-color: $color-red;
    a {
      color: $color-white;
    }

    &:hover {
      background-color: $color-dark-red;
    }
  }
}
