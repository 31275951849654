h2 {
  margin-top: 2rem;

  // @include get-fontsize(320, 1200, 32, 48, 1.2);
}

// Grill auf Startseite, Suchst Du einen bestimmten Grill
.p-5 {
  padding: 2rem 1rem !important;

  .suggestions__items {
    @include flex-wrap(wrap);

    .suggestions__box {
      width: 96%;
      margin: 2%;

      @include flexbox;
      @include justify-content(space-between);
      @include flex-direction(column);

      .suggestions__txt {
        text-align: center;
      }
    }
  }
}

// Suchfeld
.suggestions__search {
  margin: 30px 0;
}

// Rezept Card Footer (min, Erfahrung, Rating)
.card-footer {
  @include flexbox;
  @include justify-content(space-between);
}

// archiv, z.B. Rezepte
body.archive {
  main {
    padding: 0 4%;

    article {
      time.updated {
        font-size: smaller;
      }
    }
  }
}

// Top Slider content
.slider-content {
  background: url("./../../images/slider-bg.jpg") no-repeat center right;
  max-height: 370px;
  position: relative;
  min-height: 180px;

  .klecks {
    background: url("https://www.grill-profis.eu/wp-content/themes/wsam-portal/dist/images/klecks-bg.svg") no-repeat top center;
    background-size: 100%;

    // padding: 10vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 160px;
    max-width: 700px;

    .claim {
      color: $color-white;
      display: block;      
      font-weight: bold;
      text-align: center;
      margin-top: -65px;
      font-size: 1.5rem;

      @media (min-width: 500px) {
        font-size: 1.8rem;
        margin-top: -50px;
      }

      @media (min-width: 992px) {
        font-size: 2.25rem;
      }    
    }

    .second{
      display: block;
      color: $color-white;
      font-weight: bold;
      text-align: center;      
      font-size: 2.4rem;

      @media (min-width: 500px) {
        font-size: 3rem;
      }     
      
      @media (min-width: 992px) {
        font-size: 3.5rem;
        margin-left: 130px;
        
      }       
    }

  }

  button {
    @include position(absolute, $right: inherit, $bottom: 20px);

    text-transform: uppercase;
    font-weight: bold;
    a{
      color: $color-white;
      text-decoration: none;
    }
  }
}

footer.copy-footer {
  background-color: $color-dark-grey;
  color: $color-white;

  a {
    color: $color-white;
  }


    // @include flexbox();
    // @include justify-content(space-between);
    // @include flex-direction(column);

    text-align: center;
    padding-top: 10px;

    @extend .container-inner;

    .social-links {
      fill: $color-white;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  

  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

// Rezeptkarten
.card-deck {
  .card {
    margin-bottom: 15px;

    &:hover {
      text-decoration: none;
    }
  }
}

.search-form {
  label {
    width: 65% !important;
    margin-bottom: 0;
  }
}

// Grills im Test
.select-grill {
  h2 {
    color: $color-white;
    text-transform: uppercase;
  }

  // Artikel
  .featurette {
    background-color: $color-white;
    padding: 20px 15px;

    h3 {
      text-align: left;
    }

    &:first-of-type {
      background: $color-white url("./../../images/bg-fire-top.jpg") no-repeat;
      padding-top: 80px;
    }
  }

  div.cta-btn {
    // Jetzt kaufen BTN
    .btn {
      margin-top: 2vw;
      width: 100%;
      border: 1px solid $color-dark-yellow;
      border-radius: 0;
    }

    .btn-warning {
      color: $color-black;
      background-color: $color-yellow;
      text-transform: uppercase;
      font-weight: bold;
    }

    .btn-outline-warning {
      color: $color-black;
      border-color: $color-black;
    }
  }
}

// Boxen
.boxen {
  background-color: $color-bright-bg;

  @include box-inner-shadow;

  // @include flexbox();
  // @include justify-content(center);
  // @include flex-wrap(wrap);

  position: relative;
  padding: 40px 20px;

  .dark {
    background: rgb(0 0 0 / 60%);

    @include position(absolute, 40px, 20px, 40px, 20px);

    padding: 20px;

    @include flexbox;
    @include justify-content(space-evenly);
    @include flex-direction(column);
  }

  .box {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 350px;
    height: 300px;
    margin: 2% 0;

    h3 {
      color: $color-white;

      @include font-size(30);
    }

    button {
      color: $color-white;
      border: 1px solid $color-white;

      @include font-size(18);

      font-family: Oswald, sans-serif;
    }
  }

  .left {
    background-image: url("./../../images/box1.jpg");
  }

  .center {
    background-image: url("./../../images/box2.jpg");
  }

  .right {
    background-image: url("./../../images/box3.jpg");
  }
}

// Teaser
.teaser {
  background: url("./../../images/hitze.jpg") no-repeat;

  // @include flexbox();
  // @include justify-content(space-between);
  // @include flex-direction(column);
  // @include align-items(end);

  .left{
    h2 {
      color: $color-white;

      // text-align: left;
    }
  }


    .teaser-text {
      color: $color-gold;

      @include font-size(20);

      font-family: Oswald, sans-serif;
      text-align: center;
    }
  

  .right {
    text-align: center;
  }
}

// Rezepte
.rezepte-top {
  background-color: $color-white;
  padding: 2vw 15px;

  @include box-inner-shadow;

  h2 {
    color: $color-black;
  }

  p.claim {
    text-align: center;
    color: $color-gold;
  }
}

.rezepte {
  background: $color-top-bg;

  .rezepte-inner {
    // @include flexbox();
    // @include justify-content(space-between);
    // @include flex-wrap(wrap);
    // @include flex-direction(row);

    .rezept {
      width: 280px;
      height: 210px;
      max-width: 280px;
      background-repeat: no-repeat;
      position: relative;
      padding: 10px;
      margin: 1rem auto;
      border: 2px solid $color-bright-bg;

      &::after {
        content: "\A";

        @include position(absolute, $top: 0, $left: 0);

        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 30%);
        opacity: 1;
        transition: all 0.5s;
        transition: all 0.5s;
        max-width: 280px;
      }

      &:hover {
        cursor: pointer;

        &::after {
          background: transparent;
        }
      }

      h4 {
        text-transform: uppercase;
        font-weight: bold;
        color: $color-white;
        margin: 0;
        padding: 0;

        @include position(absolute, $top: 50px, $left: auto);

        text-align: left;

        @include font-size(32);

        word-break: break-word;
        z-index: 10;
      }

      .s-date {
        @include position(absolute, $top: 10px, $left: auto);

        width: 136px;
        height: 24px;
        color: $color-gold;
        background: url("./../../images/ribbon_weiss.png") no-repeat;
        font-family: Oswald, sans-serif;

        @include font-size(12);

        text-transform: uppercase;
        padding: 2px 5px;
        z-index: 10;
      }

      .s-r {
        display: block;

        @include position(absolute, $bottom: 10px, $left: auto);

        width: 100px;
        height: 30px;
        z-index: 10;
        text-transform: uppercase;
        color: $color-white;

        @include font-size(18);

        text-decoration: underline;
        font-family: Oswald, sans-serif;
      }
    }
  }
}

.grill-help {
  background-color: $color-bright-bg;

  @include box-inner-shadow;

  h2 {
    color: $color-dark-red;
  }

  p {
    color: $color-black;
    text-align: center;
  }

  ul {
    padding: 0;

    // @include flexbox();
    // @include justify-content(center);
    // @include flex-wrap(wrap);

    li {
      list-style: none;
      margin-top: 2vw;

      // width: 50%;
      // flex: 1 1 10rem; /*flex-grow, flex-shrink, flex-basis*/
      a {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);

        color: $color-black;

        span {
          font-weight: 600;
        }

        img {
          opacity: 0.7;
        }

        &:hover {
          text-decoration: none;

          img {
            opacity: 1;
          }

          span {
            color: $color-red;
          }
        }
      }
    }
  }
}

.two-column-text {
  background-color: $color-bright-bg;

  @include box-inner-shadow;

  h2 {
    color: $color-black;
    margin-bottom: 0;
  }

  .claim {
    text-align: center;
    color: $color-gold;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
  }

  .top {
    img {
      display: block;
      margin: 0 auto 2rem;
    }
  }

  // .two-column-text-content {
  //   .col {
  //     margin-top: 2rem;

  //     h4 {
  //       text-align: left;
  //       margin-bottom: 0;
  //     }
  //   }
  // }
}

.rating {
  @include flexbox;
  @include align-items(center);

  &-text {
    font-weight: bold;
    margin-right: 20px;
  }

  &-images {
    img {
      width: 15px;
      height: 18px;
      margin: 0 2px;
    }
  }
}


.archive-container{
  background-color: $color-top-bg;
}

.container-content{
  background-color: $color-bright-bg;
}

.page-container{
  padding: 24px;
}

.archive-text.grill-help{
  p{
    text-align: left;
  }
  ul{
    max-width: 80%;
    margin: 0 auto 2rem;
    padding: 40px;
    font-size: larger;
    background-color: $color-white;
    li{
      list-style-position: outside;
      list-style-type: disclosure-closed;
      margin: 10px 0;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }
}