// ++++++++++++++++++ min 576px +++++++++++++++
@include media-breakpoint-up(sm) {
  .card-deck {
    .card {
      margin-bottom: 15px;
    }
  }

  // .grill-help {
  //   ul {
  //     @include justify-content(space-around);

  //     li {
  //       @include flex(1, 0, 33%);
  //     }
  //   }
  // }

  // .boxen {
  //   .box {
  //     @include flex(0, 0, 46%);
  //   }
  // }

  .two-column-text {
    .top {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);

      img {
        margin: 0;
      }

      .right {
        margin-left: 20px;
      }
    }
  }

  body.single {
    .alert-warning {
      .badge{
        font-size: 1rem;
      }      
    }
  }

  body.single{
    aside.sidebar{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .sidebar-neu, .sidebar-top-3{
        margin: 2%;
      }      
      > div, > section, > .sidebar-top-3{
        flex: 0 0 46%;
        max-width: 290px;
        margin: 2%;
      }

      .sidebar-top-3{
        li a p.display-3{
          display: none;
        }
      }

      .sidebar-top-3:first-of-type{
        margin: 2%;
        max-width: 290px;
      }

      .sidebar-news, .sidebar-zutaten {
        margin: 2%;
      }

      #sheet{
        max-width: 560px;
        margin: 0 auto;
      }
      
    }
  }
}
