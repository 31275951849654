.wp-caption {
  background: #f9f8ef;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgb(0 0 0 / 20%);

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
  }

  img {
    box-shadow: -1px -1px 1px rgb(0 0 0 / 30%);
  }

  .wp-caption-text {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2em;
  }
}
