/** pagination */
.navigation li{
    margin: 0 4px;
    list-style: none;
}
.navigation li a,
.navigation li a:hover,
.navigation li.active a,
.navigation li.disabled {
    color: #fff;
    text-decoration: none;
}
  
.navigation li a,
.navigation li a:hover,
.navigation li.active a,
.navigation li.disabled {
    background-color: $color-gold;
    border-radius: 3px;
    cursor: pointer;
    padding: 6px 8px;
}
  
.navigation li a:hover,
.navigation li.active a {
    background-color: $color-red;
}
