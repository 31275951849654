.banner {
  .nav li {
    @extend .nav-item;

    a {
      @extend .nav-link;
    }
  }
}

header {
  max-width: 100vw;
}

.navbar {
  background-color: $color-dark-grey;
  padding: 0.5rem 15px;
}

.nav-primary{
  height: 0;
  padding: 0;
  background-color: $color-white;
  position: inherit;  
  .container {
    background-color: $color-white;
    z-index: 100;
  }  
  .navbar-collapse {
    @include justify-content(center);
  }  
}

ul#menu-top-menu {
  border-top: 1px solid white;
  margin-top: 0;

  @include flex-wrap(nowrap);
  @include flex-direction(column);

  font-family: Oswald, sans-serif;

  > li {
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid #4b4b4b;

    a {
      width: calc(100% - 60px);
      text-transform: uppercase;

      @include font-size(20,40);
      color: $color-black;
      padding: 0 0.5rem;      
    }

    // Arrow down open .sub-menu
    &.menu-item-has-children > span {
      display: block;

      @include position(absolute,$top:14px,$right:10px);

      cursor: pointer;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0;
      border-color: $color-dark-grey transparent transparent transparent;

      // Wenn Submenu offen
      &.subshow{

        border-width: 0 6px 8px;        
        border-color: transparent transparent  $color-dark-grey transparent;
      }
    }

    &:last-of-type {
      border: none;
    }
  }

  .sub-menu {
    display: none;
    background-color: $color-white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    list-style-type: none;
    padding: 0 20px;

    > li {
      border-bottom: 1px solid  $color-red;
      padding: 3px 0;

      a {
        width: 100%;
      }

      &:last-of-type {
        border: none;
      }
      &.menu-item-has-children{
        margin-bottom: 0;
      }
      .sub-menu {
        display: block;
        position: relative;
        box-shadow: none;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-right: 20px;
        max-width: 100%;
        a{
          color: $color-yellow;
        }
      }      
    }

  }
}

// Suche Banner
.top-search {
  z-index: 100;
  background-color: $color-top-bg;

  // padding: 10px 0;

  // &-container {
  //   @include flexbox();
  //   @include align-items(center);
  //   @include justify-content(space-between);
  // }
  // Logo
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;

    @include flex(0, 0, auto);

    width: 20%;

    img {
      max-width: 100px;
      height: auto;
      width: 100%;
    }
  }

  // Suchform
  form {
    position: relative;
    margin-bottom: 0;

    @include flex(0, 0, auto);

    width: 60%;

    // Suche Textfeld
    #search {
      background-color: $color-light-grey;
      color: $color-light-brown;
      border: 1px solid $color-light-brown;

      @include font-size(12);

      width: 100%;
      padding: 8px;
    }

    // Lupe Icon
    .searchsubmit{
      background-image: url("./../../images/search.svg");

      @include position(absolute,$top:7px,$right:10px);
      background-repeat: no-repeat;
      background-position: center;
      text-indent: -99999px;
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;     
    }
  }

  // Nav toggle BTN
  .navbar-toggler {
    @include flex(0, 0, auto);

    border-color: $color-light-brown;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 3px;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    .navbar-toggler-icon {
      position: relative;
      height: $burger-height;
      margin: 20px 4px;
      background: $color-light-brown;
      width: $burger-width;

      &::before {
        content: "";

        @include position(absolute,$top:-8px,$left:0);

        width: $burger-width;
        height: $burger-height;
        background: $color-light-brown;
        box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
        transition: 0.5s;
      }

      &::after {
        content: "";

        @include position(absolute,$bottom:-8px,$left:0);

        width: $burger-width;
        height: $burger-height;
        background: $color-light-brown;
        box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
        transition: 0.5s;
      }
    }
  }
}

// Top Navi
.nav-primary {
  // height: 0;
  // padding: 0;
  // background-color: $color-white;
  // position: inherit;

  // .container {
  //   background-color: $color-white;
  //   z-index: 100;
  // }

  // .navbar-collapse {
  //   @include justify-content(center);
  // }

  // ul#menu-top-menu {
  //   // margin-top: 0;
  // }
}