#comments{
  background-color: $color-top-bg;
  h2{
    color: $color-dark-yellow;
  }

  h3.comment-reply-title{
    color: $color-dark-yellow;
  }

  .comment-content{
    color: $color-bright-bg;
  }

  .comment-author{
    .fn, .says{
      color: $color-white;
    }
  }
  .comment-metadata a{
    color: $color-yellow;
    font-size: small;
  }

  img.avatar{
    border-radius: 50px;
  }

  .comment-form-comment label{
    color: $color-yellow;
  }

  .comment-reply-link{
    display: inline-block;
    padding: 0.375rem 0.75rem;
    background-color: $color-white;
    border-radius: 0.2rem;
  }

  #cancel-comment-reply-link{
    margin-left: 20px;
  }

}

.comment-list {
  // @extend .list-unstyled;
  list-style: none;
}

.comment-list ol {
  list-style: none;
}

ol.children{
  margin-top: 20px;
  li.depth-2{
    margin-top: 20px;
  }
}

li.depth-1{
  border-top: 2px solid $color-light-brown;
  padding: 20px;
}

.comment-form p {
  @extend .form-group;
}

.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}

.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-secondary;
}

.must-log-in{
  color: $color-white;
  padding: 0 40px;
}
