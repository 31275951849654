#breadcrumbs {
  font-family: Oswald, sans-serif;
  margin: 10px 15px;
  color: $color-light-brown;

  span.nav-separator {
    display: inline-block;
    margin: 0 3px;
  }

  a.nav-item {
    .breadcrumb-home {
      background: url("https://www.grill-profis.eu/wp-content/themes/wsam-portal/resources/assets/images/gp-home.svg") no-repeat;
      background-size: cover;
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: sub;
      text-indent: -999999px;
    }
  }
}

body.single {
  #breadcrumbs {
    color: white;

    a {
      color: $color-yellow;
    }
  }
}
