*,
*::before,
*::after {
  @include box-sizing;
}

body {
  background: $color-body-bg;
  font-size: 100%;
  color: $color-black;
}

main {
  padding-top: 0 !important;
  max-width: 100%;
  
  // background: transparent;

  // background-color: $color-bright-bg;
}

body.home {
  main {
    width: 100%;
  }
}

// Typography
h1,h2,h3 {
  @extend %headline-global;

}
h1 {
  padding-top: 1rem;
	font-size: 2rem;
}
h2{
  font-size: 1.5rem;
}
h3{
  font-size: 1.7rem;
}



h1.taxonomy-h1{
  color: $color-dark-yellow;
}

h4 {
  // font-family: $font-family-monospace;
  @extend %headline-global;


  color: $primary;
}

.svg {
  max-width: 100%;
}

// .row {
//   margin-left: 0;
//   margin-right: 0;
// }

.font-size-x-large {
  font-size: 150%;
}

// ++++++++++++++++  min 1200px +++++++++++++++++++++++++++++++++++

.container {
  // padding: 0;
  max-width: 1600px;

  &-inner {
    h2 {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    padding: 2rem 0;
  }
}

article.card{
  width: 100%;
  .found-in{
    background-color: $color-yellow;
  }
  .entry-meta.px-3{
    padding: 0 !important;
  }
}

.container-xl{
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}

// @include media-breakpoint-up(xl) {
//   .container {
//     &-inner {
//       h2 {
//         margin-top: 2rem;
//       }
//     }
//   }
// }


// Loading Spinner
.at-spinner {
	width: 30px;
	height: 30px;
	border: 4px #ddd solid;
	border-top: 4px #30464f solid;
	border-radius: 50%;
	animation: sp-anime 0.8s infinite linear;
	display: none;
	margin: 5px auto;
}

@keyframes sp-anime {
	100% {
		transform: rotate(360deg);
	}
}

/* polaroid */
img.alignright.polaroid {
  padding: 62px 20px 30px 12px;
  width: 245px;
  height: 293px;
  background: url("/images/polaroid-01.png") no-repeat;
  background-size: cover;
  transform: rotate(9deg);

  // margin-right: -170px;
  margin-left: 30px;
}

img.alignleft.polaroid {
  padding: 62px 20px 30px 12px;
  width: 245px;
  height: 293px;
  background: url("/images/polaroid-01.png") no-repeat;
  background-size: cover;
  transform: rotate(-9deg);
  margin-right: 35px;
  margin-left: 15px;
}

//table
.entry-content,
.archive-text {

  table{
    margin-bottom: 20px;
  }
  
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 3px 5px;
  }

}