/* stylelint-disable*/
@import "common/variables";
/** Import everything from autoload */
// @import "./autoload/_bootstrap.scss"; // NOT working
@import "../../../../wsam-portal/node_modules/bootstrap/scss/bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/** Import theme styles */
@import "common/global";
@import "layouts/fonts";
@import "components/buttons";
@import "components/ctas";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
// @import "layouts/custom-typo";
@import "layouts/header";
@import "layouts/breadcrumb";
@import "layouts/faq";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/single";
@import "layouts/recipes";
@import "layouts/posts";
@import "layouts/tinymce";
@import "components/polaroid";
@import "components/pagination";
@import "components/alert";
@import "components/relatedcontent";
@import "layouts/breakpoints";
