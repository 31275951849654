/* oswald-regular - latin */
@font-face {
    font-family: Oswald;
    font-style: normal;
    font-weight: 400;
    src:
 local(""),
         url("/fonts/oswald-v49-latin-regular.woff2") format("woff2"),
 
         url("/fonts/oswald-v49-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* oswald-500 - latin */
  @font-face {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    src:
 local(""),
         url("/fonts/oswald-v49-latin-500.woff2") format("woff2"),
 
         url("/fonts/oswald-v49-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* oswald-600 - latin */
  @font-face {
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    src:
 local(""),
         url("/fonts/oswald-v49-latin-600.woff2") format("woff2"),
 
         url("/fonts/oswald-v49-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* oswald-700 - latin */
  @font-face {
    font-family: Oswald;
    font-style: normal;
    font-weight: 700;
    src:
 local(""),
         url("/fonts/oswald-v49-latin-700.woff2") format("woff2"),
 
         url("/fonts/oswald-v49-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }




/* londrina-solid-100 - latin */
@font-face {
    font-family: "Londrina Solid";
    font-style: normal;
    font-weight: 100;
    src:
 local(""),
         url("/fonts/londrina-solid-v15-latin-100.woff2") format("woff2"),
 
         url("/fonts/londrina-solid-v15-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* londrina-solid-300 - latin */
  @font-face {
    font-family: "Londrina Solid";
    font-style: normal;
    font-weight: 300;
    src:
 local(""),
         url("/fonts/londrina-solid-v15-latin-300.woff2") format("woff2"),
 
         url("/fonts/londrina-solid-v15-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* londrina-solid-regular - latin */
  @font-face {
    font-family: "Londrina Solid";
    font-style: normal;
    font-weight: 400;
    src:
 local(""),
         url("/fonts/londrina-solid-v15-latin-regular.woff2") format("woff2"),
 
         url("/fonts/londrina-solid-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* londrina-solid-900 - latin */
  @font-face {
    font-family: "Londrina Solid";
    font-style: normal;
    font-weight: 900;
    src:
 local(""),
         url("/fonts/londrina-solid-v15-latin-900.woff2") format("woff2"),
 
         url("/fonts/londrina-solid-v15-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }


  /* roboto-slab-regular - latin */
@font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    src:
 local(""),
         url("/fonts/roboto-slab-v24-latin-regular.woff2") format("woff2"),
 
         url("/fonts/roboto-slab-v24-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-slab-500 - latin */
  @font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    src:
 local(""),
         url("/fonts/roboto-slab-v24-latin-500.woff2") format("woff2"),
 
         url("/fonts/roboto-slab-v24-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-slab-700 - latin */
  @font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 700;
    src:
 local(""),
         url("/fonts/roboto-slab-v24-latin-700.woff2") format("woff2"),
 
         url("/fonts/roboto-slab-v24-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-slab-900 - latin */
  @font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 900;
    src:
 local(""),
         url("/fonts/roboto-slab-v24-latin-900.woff2") format("woff2"),
 
         url("/fonts/roboto-slab-v24-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }