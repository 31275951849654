// ++++++++++++++++++++  min 480px +++++++++++++++++++++
@media (min-width: 480px) {
  .p-5 {
    .suggestions__items {
      .suggestions__box {
        width: 48%;
        margin: 1%;
      }
    }
  }

  // .boxen {
  //   .box {
  //     @include flex(0, 0, 46%);

  //     margin: 2%;
  //   }
  // }
}