/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: ($custom-spacer / 2) auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: ($custom-spacer / 2);
  height: auto;
}

@include media-breakpoint-up(sm) {
  .alignleft {
    float: left;
    margin-right: ($custom-spacer / 2);
  }

  .alignright {
    float: right;
    margin-left: ($custom-spacer / 2);
  }
}

/** Captions */
.wp-caption {
  @extend .figure;
}

.wp-caption img {
  @extend .figure-img;
  @extend .img-fluid;
}

.wp-caption-text {
  @extend .figure-caption;
}

/** Text meant only for screen readers */
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

/** archive pages images same height */
.archive-container{
  img.card-img-top{
    object-fit: cover;
    height: 200px;
  }
}

.widget_recent_entries, .widget_text{
  background-color: $color-white;
  max-width: 290px;
  margin: 2rem auto;
  padding: 20px;
  ul{
    list-style-type: none;
    padding-left: 0;
    li{
      margin: 10px 0;
    }
  }
}
.widget_text{
  background-color: $color-red;
  h3{
    color: $color-white;
  }
}