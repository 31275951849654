.gp_related {
  background-color: $color-body-light;

  h3 {
    color: $color-red;
  }

  ul {
    margin-bottom: 0px;
  }
}

.archive-text {
  + .gp_related {
    margin-top: 15px;
  }
}
