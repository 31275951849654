// ++++++++++++++++  min 992px +++++++++++++++++
@include media-breakpoint-up(lg) {

  .container-xl{
    padding-left: 15px;
    padding-right: 15px;
  }

  header.banner {
    height: 170px;
  }

  .top-search {
    @include position(fixed, $right: 0, $left: 0);

    height: 130px;
    .navbar-toggler {
      display: none;
    }

    form {
      width: 50%;
      margin-right: 25%;

      // Suche Textfeld
      #search {
        @include font-size(16);

        padding: 16px;
      }
      
      .searchsubmit{
        top: 19px;
      }
      
    }
  }

  .nav-primary {
    top: 130px !important;
    height: 40px;
    padding: 0;
    background-color: $color-white;
    position: fixed;

    ul#menu-top-menu {
      border: none;

      @include flex-direction(row);

      li {
        border: none;
        padding: 0 20px 0 0;

        a {
          width: inherit;

          @include font-size(18);
        }

        &.menu-item-has-children > span {
          display: none;
        }

        &:hover {
          > .sub-menu {
            display: block;
          }
        }
      }

      .sub-menu {
        @include position(absolute, $left: 15px);

        min-width: 200px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        padding: 0;

        > li {
          margin: 3px 0;
          padding: 3px 10px 3px 0;

          a {
            white-space: nowrap;

            @include font-size(14);

            text-transform: none;
          }

          &:hover {
            background-color: $color-bright-bg;
          }
          &.menu-item-has-children{
            margin-bottom: 0;
          }          
        }
      }
    }
  }

  .slider-content {
    height: 370px;

    .klecks {
      padding: 0;

      @include position(absolute, $top: 5%, $right: 5%);

      width: 700px;
      height: 225px;

      .claim {
        margin-left: 140px;
      }
    }

    button {
      right: 10%;
    }
  }

  .select-grill {
    .featurette {
      padding: 3rem;
    }

    // div.cta-btn {
    //   // @include flexbox();
    //   // @include justify-content(space-between);
    //   // @include align-items(center);
    //   // Jetzt kaufen BTN
    //   .btn {
    //     width: 48%;
    //   }
    // }
  }

  // .grill-help {
  //   ul {
  //     @include justify-content(space-between);

  //     li {
  //       margin-top: 2vw;
  //       max-width: 156px;
  //     }
  //   }
  // }

  .rezepte {
    &-inner {
      .rezept {
        h4 {
          @include font-size(30);
        }
      }
    }
  }

  .teaser {
    // @include flex-direction(column);
    // @include justify-content(space-between);
    // @include align-items(end);

    background-size: cover;

    
      h2 {
        text-align: left;
      }

      .teaser-text {
        text-align: left;
      }
    

    // .left {
    //   @include flex-basis(min-content);
    // }

    .right {
      text-align: right;
    }
  }

  // .boxen {
  //   @include justify-content(space-between);

  //   .box {
  //     @include flex(0, 0, 28%);
  //   }
  // }
  body.single {
    #open-sheet{
      display: none;
    }
    aside.sidebar{
      display: block;

      // margin-top: 70px;
      padding-bottom: 3rem;

      #sheet {
        position: inherit;
        opacity: 1;
        visibility: visible;
        max-width: 290px;
        margin: inherit;
        margin-top: 0;
      }      
      #sheet .overlay {
        display: none;
      }      
      #sheet .contents{
        top: 0;
        height: auto;
        max-height: inherit;
        height: inherit;
        width: 100%;
      }
      #sheet .close-sheet {
        display: none;
      }  

      .sidebar-zutaten {
        background-color: transparent;
        header.controls{
          display: none;
        }

        // Tabelle Zutaten in Sidebar
        margin: 0; // Not top 2rem, ist die erste oben
        table{
          tbody{
            th{
              font-size: 0.875rem;
            }
          }
        }

        &-top {
          height: 100px;

          // background: url("/images/sidebar1-top-yellow.svg") no-repeat;
          // background-size: cover;
          .top-content{
            width: 100px;
          }
        }   

        // &-bottom {
        //   // height: 80px;
        //   // // background: url("/images/sidebar1-bottom-yellow.svg") no-repeat;
        //   // background-size: 100%;
        //   // display: block;
        // }       

        .center {
          height: auto;
          overflow: visible;

          // position: relative;

          // &-left {
          //   // background: url("/images/sidebar1-left-yellow.svg") repeat-y;
          //   // background-size: contain;
          //   // display: block;
          //   // position: absolute;
          //   // left: 0;
          //   // top: 0;
          //   // width: 30px;
          //   // height: 100%;
          // }
  
          // &-right {
          //   // background: url("/images/sidebar1-right-yellow.svg") repeat-y;
          //   // background-size: contain;
          //   // display: block;
          //   // position: absolute;
          //   // right: 0;
          //   // top: 0;
          //   // width: 30px;
          //   // height: 100%;
          // }

          .center-center {
            min-height: 300px;
            margin-left: 30px;
            width: 230px;
            background-color: white;
          }          
        }

      }      
  
      > div, > section, > .sidebar-top-3{
        // flex: 0 0 100%;
        margin: 2rem 0 0;
      }

      .sidebar-top-3{
        margin: 0;
        background-color: transparent;
        li a p.display-3{
          display: block;
        }
      }

      .sidebar-top-3:first-of-type{
        margin: 0;
      }

      .sidebar-news, .sidebar-neu{
        margin: 2rem 0 0;
      }


      div.bg-sidebar{
        .top{
          background-size: cover;
          background-repeat: no-repeat;
        }
        .bottom {
          background-repeat: no-repeat;
          height: 80px;
          background-size: 100%;
          display: block;
        }
        .center {
          position: relative;
          .left, .right {
            display: block;
            background-repeat: repeat-y;
            background-size: contain;
            position: absolute;
            top: 0;
            width: 30px;
            height: 100%;            
          }
          .left{
            left: 0;
          }
          .right {
            right: 0;
          }
        }
      }

      .sidebar-zutaten{
        .top{
          background: url("/images/sidebar1-top-yellow.svg");
        }
        .bottom{
          background: url("/images/sidebar1-bottom-yellow.svg");
        }
        .center {
          .left {
            background: url("/images/sidebar1-left-yellow.svg");
          }
          .right {
            background: url("/images/sidebar1-right-yellow.svg");
          }
        }
      }

      .sidebar-top-3{
        .top{
          background: url("/images/sidebar1-top-white.svg");
        }
        .bottom{
          background: url("/images/sidebar1-bottom-white.svg");
        }
        .center {
          .left {
            background: url("/images/sidebar1-left-white.svg");
          }
          .right {
            background: url("/images/sidebar1-right-white.svg");
          }
          .center-center{
            min-height: 300px;
            margin-left: 30px;
            width: 230px;
            background-color: white;            
          }
        }
      }

      .sidebar-neu{
        background-color: transparent;
        .top{
          height: 100px;
          background: url("/images/sidebar1-top-red.svg");
        }
        .bottom{
          background: url("/images/sidebar1-bottom-red.svg");
        }
        .center {
          .left {
            background: url("/images/sidebar1-left-red.svg");
          }
          .right {
            background: url("/images/sidebar1-right-red.svg");
          }
          .center-center{
            min-height: 300px;
            margin-left: 30px;
            width: 230px;
            background-color: white;
          }
        }
      } 

      .sidebar-news{
        background-color: transparent;
        .top{
          height: 30px;
          background: url("/images/sidebar1-top-brown.svg");
        }
        .bottom{
          background: url("/images/sidebar1-bottom-brown.svg");
        }
        .center {
          .left {
            background: url("/images/sidebar1-left-brown.svg");
          }
          .right {
            background: url("/images/sidebar1-right-brown.svg");
          }
          .center-center{
            min-height: 300px;
            margin-left: 30px;
            width: 230px;
            background-color: #958272;
          }
        }
      }              

    }
  }  


  body.post-type-archive-recipes{
    main{
      padding-left: 15px;
      padding-right: 15px;
    }
    .top-search {
      padding-left: 0;
      padding-right: 0;       
    }
    main.main{
      margin-top: -100px;
    }
    aside.sidebar{
      margin-top: 167px;
      background-color: transparent;
    }

    // .tagesrezept{
    //   // &-top{
    //   //   height: 450px;
    //   // }
    //   // &-bottom{
    //   //   height: 150px;
    //   // }
    // }
    .recipes-h1{
      margin-top: 98px;
    }
    .all-recipes{
      .col-lg-4{
        max-width: 32%;
      }
    }

  }


}
