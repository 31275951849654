img {
  max-width: 100%;
  height: auto;
}

.wp-post-image {
  box-shadow: 0 0.2rem 1.2rem rgb(0 0 0 / 20%);
}

.post-img {
  .wp-post-image {
    margin: auto;
    display: block;
  }
}
