.gp_faq_section {
  padding-top: 1em;

  h2 {
    font-size: 2rem;
  }

  .gp_faq_item_wrapper {
    margin-bottom: 1.4em;

    h3 {
      font-size: 1.5rem;
    }
  }
}
