body.no-scroll{
  position: fixed;
  overflow: hidden;
}
body.single {
    // Rezept Card Footer (min, Erfahrung, Rating) -> Single Page
    main.col-lg-9 {
      padding: 0;
    }
  
    h1 {
      text-align: left;
      color: $color-red;
      text-transform: uppercase;

      // margin-top: 2rem;
      margin-bottom: 1rem;
      word-wrap: break-word;
    }
  
    h2 {
      color: $color-red;
      text-transform: uppercase;
      // font-family: Oswald, sans-serif;
  
      &.vorbereitung {
        background: url("/images/Icon_Vorbereitung.svg") no-repeat top center;
        padding-top: 66px;
        background-size: 64px;
      }
  
      &.zubereitung {
        background: url("/images/Icon_Zubereitung.svg") no-repeat top center;
        padding-top: 90px;
        margin-bottom: 30px;
        background-size: 60px;
      }
    }
  
    h4 {
      &.schmecken {
        background: url("/images/Icon_Teller.svg") no-repeat bottom center;
        padding-bottom: 61px;
        font-family: Oswald, sans-serif;
        font-weight: normal;
        background-size: 70px;

        // max-width: 954px;
        margin: 30px 0;
      }
    }
  
    .content.row {
      // margin: 0 auto;
      main{
        background-color: transparent;
        .container-content{
          background-color: $color-bright-bg;
        }
      }
    }
  
    // Zeit, Erfahrung, Rating
    .entry-meta {
      font-family: Oswald, sans-serif;      
      background-color: transparent;
  
      .badge{
        font-size: 0.75rem;
      }
  
      span.badge-dark {
        background-color: transparent;
  
        .data {
          color: $color-gold;
        }
  
        .ezr{
          color: $color-black;
        }
      }

      // Last update
      time {
        background-color: transparent;
        color: $color-gold;
      }
    }
  
    article {
      // margin: 1rem 20px 0 20px;
      position: relative;
      header{
        .post-img{
          margin-bottom: 2rem;
        }
      }
  
      p.intro {
        padding: 20px 0;
        color: $color-gold;
        border-top: 2px dotted $color-gold;
        border-bottom: 2px dotted $color-gold;
      }
    }

    #open-sheet{
      position: fixed;
      bottom: 0;
      width: 94%;
      margin: 0 auto;
      z-index: 100;
      background: url("/images/top-basket.png") no-repeat calc(50% - 70px) center;
      background-size: 46px;
      background-color: #fab921;
      border: none;
      border-radius: 0;
      width: 100%;
      text-transform: uppercase;
      font-weight: bold;
    }    
  
    aside.sidebar {
      background-color: $color-body-bg;
  
      .table-header {
        h2 {
          color: $color-black;
          margin-top: 0;
        }
      }


      // +++++++++++++++++++++++++++++++++++++++++++++++++++

      #sheet {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 20;
        visibility: visible;

        // transition: opacity 0.5s, visibility 0.5s;
      }
      #sheet[aria-hidden="true"] {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
      #sheet .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: #888;
        opacity: 0;
      }
      #sheet .contents {
        // border-radius: 1rem 1rem 0 0;
        // background: var(--background);
        position: relative;
        overflow-y: hidden;

        // --default-transitions: transform 0.5s, border-radius 0.5s;
        // transition: var(--default-transitions);
        // transform: translateY(0);
        transition: all 0.5s ease;
        max-height: 100vh;
        height: 100vh;
        max-width: 70rem;
        box-sizing: border-box;
        padding: 1rem;
        padding-top: 1rem;
        top: 100vh;
        z-index: 101;
      }

      // #sheet .contents:not(.not-selectable) {
      //   transition: var(--default-transitions), height 0.5s;
      // }
      #sheet .contents.fullscreen {
        border-radius: 0;
      }

      // #sheet[aria-hidden="true"] .contents {
      //   transform: translateY(100%);
      // }
      #sheet[aria-hidden="false"] .contents {
        // height: 100vh;
        top: 0;
      }      
      #sheet .draggable-area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 3rem;
        margin: auto;
        padding: 1rem;
        cursor: grab;
      }
      #sheet .draggable-thumb {
        width: inherit;
        height: 0.25rem;
        background: var(--divider);
        border-radius: 0.125rem;
      }
      #sheet .close-sheet {
        position: absolute;
        right: 5px;
        top: 5px;
        border: none;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        background-color: #fab921;
        font-size: 2rem;
      }
      #sheet .body {
        height: 100%;
        overflow-y: auto;
        gap: 1rem;
      }      

      // +++++++++++++++++++++++++++++++++++++++++++++++++++
  
      .sidebar-zutaten {
        // max-width: 290px;
        // margin: 2rem auto;
        overscroll-behavior: contain;
        width: 94%;
        margin: 0 auto;
        background-color: #fab921;
        &-top {
          height: 50px;          
          .top-content{
            background: url("/images/top-basket.png") no-repeat center;
            height: 50px;
          }
        }
  
        // &-bottom {
        //   display: none;
        // }
  
        .center {

          height: calc(100vh - 100px);
          overflow: scroll;
          
          // &-left {
          //   display: none;
          // }
  
          // &-right {
          //   display: none;
          // }

          // hier ist die Zutatentabelle drin
          .center-center {
            flex: 0 0 224px;

            // max-width: 224px;
            padding: 10px;
            overflow: hidden;
            background-color: white;
          }
        }
  
        // Tabelle Zutaten in Sidebar
        table{
          tbody{
            th{
              padding-left: 0;
              padding-right: 2px;
            }
          }
        }      
      }


  
      .sidebar-neu {
        max-width: 290px;
        margin: 2rem auto;
        background-color: #dc3545;
  
        h4,h3{
          font-size: 1.25rem;
          font-family: "Roboto Slab", serif;
        }      
  
        // &-top {
        //   height: 95px;
        //   //background: url("/images/table-neu-top.svg") no-repeat center;
        //   // background: url("http://grill-profis-eu.test/wp-content/themes/wsam-portal/dist/images/table-neu-top.svg") no-repeat center;
          
        // }
  
        // &-bottom {
        //   height: 50px;
        //   // background: url("/images/table-neu-bottom.svg") no-repeat center;
        // }
  
        &-center {
          display: flex;

          .center-center{
            background-color: white;
          }

          // background-color: white;
  
          // &-left {
          //   // background: url("/images/table-neu-left.svg") repeat-y top center;
          //   flex: 0 0 33px;
          //   max-width: 33px;
          // }
  
          // &-right {
          //   // background: url("/images/table-neu-right.svg") repeat-y top left;
          //   flex: 0 0 33px;
          //   max-width: 33px;
          // }
          // hier ist die Zutatentabelle drin
          // &-center {
          //   flex: 0 0 224px;
          //   max-width: 224px;
          //   overflow: hidden;
          //   background-color: white;
          // }
        }
  
        // Tabelle Zutaten in Sidebar
        table{
          tbody{
            th{
              padding-left: 0;
              padding-right: 2px;
            }
          }
        }      
      }
      
      .sidebar-top-3, .sidebar-news{
        max-width: 290px;
        margin: 2rem auto;
        &:first-of-type{
          margin-top: 0;
        }
        h3,h4{
          font-size: 1.25rem;
          font-family: Oswald, sans-serif;
        }
        &:not(:first-of-type){
          // margin-top: 40px;
          padding-top: 10px;

          // border-top: 1px solid #f4efe3;
        }
      }
            
      .sidebar-top-3{
        background-color: white;
        h4{
          position: relative;
          font-size: 1.2rem;
          // padding-left: 70px;
          // max-width: 90%;
          // &::before{
          //   content: "";
          //   position: absolute;
          //   left: 0;
          //   top: -10px;
          //   background: url("/images/icon-huhn.svg") no-repeat;
          //   width: 56px;
          //   height: 55px;
          // }
        }
      } 
  
      .sidebar-news{
        .top{
          display: none;
        }
        background-color: #958272;
        a.btn-outline-light{
          font-size: 0.875rem;
        }
        .news-content:not(:first-of-type){
          border-top: 1px solid white;
          margin-top: 40px;
          padding-top: 30px;          
        }
      }

      // Tipp-Widget
      .widget:last-of-type{
        background-color: black;
        .inner{
          background: url("/images/oven.jpg") no-repeat;
          background-size: cover;
          padding: 15px 0;
          p{
            color: $color-white;
          }
        }        
      }

      div.bg-sidebar{
        .bottom {
          display: none;
        }
        .center {
          .left {
            display: none;
          }
          .right {
            display: none;
          }
        }       
      }

      h3{
        font-family: Oswald, sans-serif;
        font-size: 1.25rem;
      }
      a.btn{
        font-size: 1rem;
        font-family: Oswald, sans-serif;
        width: 80%;
        margin: 0 auto;
      }      
  
    }
  
    img.alignright.polaroid {
      padding: 62px 20px 30px 12px;
      width: 245px;
      height: 293px;
      background: url("/images/polaroid-01.png") no-repeat;
      background-size: cover;
      transform: rotate(9deg);

      // margin-right: -170px;
      margin-left: 30px;
    }
  
    img.alignleft.polaroid {
      padding: 62px 20px 30px 12px;
      width: 245px;
      height: 293px;
      background: url("/images/polaroid-01.png") no-repeat;
      background-size: cover;
      transform: rotate(-9deg);
      margin-right: 35px;
      margin-left: 15px;
    }
 

    .tipbox{
      display: flex;
      width: 100%;
      flex-direction: column;
      div{
        display: flex;
        height: auto;
        margin: 0;
        position: relative;
      }
      .tip_white{

        background-color: #f4efe3;
        clip-path: polygon(100% 0%, 100% 70%, 50% 95%, 50% 95%, 0 70%, 0 0);
        height: 120px;
        z-index: 2;
      }
      .tipbox__tip{
        height: 100px;
        width: 100%;
        background: #f9b91f url("/images/tipp-klecks-mit-schrift.svg") no-repeat center center;
        background-size: auto 75%;
        clip-path: polygon(100% 0%, 100% 70%, 50% 100%, 50% 100%, 0 70%, 0 0);
      }
      .tipbox__text{

        margin-top: -120px;
        background: #d8cec2;
        padding: 120px 20px 20px 50px;
        width: 100%;
        flex-direction: column;
      }  
    }


    


    // Pros und Cons
    div.pros-cons{
        background-color: $color-white;      
        h2{
            margin: 0;
            padding: 0;
            color: $color-top-bg;
            font-size: 1.5rem;
        }  
        ul{
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            li{
                position: relative;
                display: flex;
                align-items: center;
            }
            &.pros{
                li{
                    &::before{
                        content: "+";
                        color: green;
                        font-size: 24px;
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }
            }
            &.cons{
                li{
                    &::before{
                        content: "-";
                        color: $color-red;
                        font-size: 24px;
                        font-weight: bold;
                        margin-right: 5px;                        
                    }
                }
            }            
        }
    } 

    .rezept-beilagen{
      .beilage{
        a{
          width: 100%;
          height: 100%;
        }
      }
    }

    div.prev-next{
      div{
        max-width: 200px;
        border: 1px solid #958272;
        position: relative;
        a{
          height: 100%;
          span{
            text-align: center;
          }
          img{
            margin-top: auto;
          }
        }
        &.next-post::after{
          content: " ";
          height: 100%;
          width: 30px;
          position: absolute;
          top: 0;
          right: -30px;
          background-color: #958272;
          clip-path: polygon(0 0, 0 100%, 100% 50%);          
        }
        &.prev-post::before{
          content: " ";
          height: 100%;
          width: 30px;
          position: absolute;
          top: 0;
          left: -30px;
          background-color: #958272;
          clip-path: polygon(0 50%, 100% 100%, 100% 0);          
        }        
      }
    }
  
  }
  