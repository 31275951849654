/** Import Bootstrap functions */
// @import "~bootstrap/scss/functions";

// $theme-colors: (
//   primary: #525ddc
// );

// These are the defaults, but you can override any values
$font-family-sans-serif: -apple-system, system-ui, blinkmacsystemfont,
  "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif !default;

// $font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-serif: "Roboto Slab", serif !default;

// $font-family-monospace: "Alfa Slab One", cursive;
$font-family-headlines: "Londrina Solid", cursive;

// $font-family-base: $font-family-sans-serif !default;
$font-family-base: $font-family-serif !default;
$font-size-base: 1.3rem;

/** Colors */
$color-white: #fff;
$color-black: #020202;
$color-gold: #968273;
$color-yellow: #f9ba1b;
$color-lightdark-yellow: #d19c15;
$color-dark-yellow: #b78914;
$color-bright-bg: #f4efe3; // BG helle section
$color-red: #c43b2d;
$color-dark-red: #a72b1e;
$color-dark-grey: #333;
$color-light-grey: #4b4b4b;
$color-body-bg: #2b2b2b;
$color-body-light: #f3f0e9;
$color-top-bg: #2b2b2b;
$color-light-brown: #c9b9a0;
$border-radius: 0.2rem;
$border-radius-lg: 0.2rem;
$border-radius-sm: 0.2rem;
$enable-print-styles: false;

// $spacer: 2rem;
$primary: $color-red;

// $container_padding_mobile: 3.126%;
$container-padding: 13.126%;
$custom-spacer: 2rem;

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

%headline-global {
  font-family: $font-family-headlines;
  text-align: center;
}

@mixin font-size($sizeValue: 16, $line: $sizeValue * 1.5) {
  // font-size: ($sizeValue) + px;
  // line-height: ($line) + px;
  font-size: ($sizeValue / 16) + rem;
  line-height: ($line / 16) + rem;
}

@mixin set-font-size($sm: 16, $md: 20, $lg: 24, $xl: 30) {
  @include font-size($sm);

  @media (min-width: 551px) {
    @include font-size($md);
  }
}

@mixin box-sizing($type: border-box) {
  box-sizing: $type;
}

@mixin box-inner-shadow() {
  box-shadow: inset 0 11px 20px -10px #ccc, inset 0 -11px 20px -10px #999;
}

@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin flexbox() {
  display: box;
  display: flexbox;
  display: flex;
}

@mixin flex-wrap($value: wrap) {
  // No Webkit Box fallback.
  flex-wrap: $value;

  @if $value == nowrap {
    flex-wrap: none;
  } @else {
    flex-wrap: $value;
  }

  flex-wrap: $value;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }

  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }

  align-items: $value;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }

  flex-direction: $value;
}

@mixin flex-basis($value: auto) {
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == "list" {
    $fg-boxflex: list.nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -moz-box-flex: $fg-boxflex;
  flex: $fg $fs $fb;
}

/** Logo Kreis in Header */
$kreis-dm: 70px;

// Hamburger Menu Button
$burger-width: 28px;
$burger-height: 3px;
